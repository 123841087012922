import React from "react";
import PropTypes from "prop-types";

const truncateToTwoDecimalPlaces = (value) => {
  return Math.floor(value * 100) / 100; // Truncate to two decimal places
};

export const CurrencyFormatter = ({
  value,
  style = "currency",
  currency = "PHP",
  minimumFractionDigits = 2,
  maximumFractionDigits = 2, // Set maximum to 2
}) => {
  // Truncate the value to two decimal places
  const truncatedValue = truncateToTwoDecimalPlaces(value);

  const formattedValue = new Intl.NumberFormat("en-US", {
    style,
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(truncatedValue);

  return <span>{formattedValue}</span>;
};

CurrencyFormatter.propTypes = {
  value: PropTypes.number.isRequired,
  style: PropTypes.oneOf(["decimal", "currency", "percent"]),
  currency: PropTypes.string,
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
};
