// src/components/RaceRunners/RaceRunners.jsx
import React, { useEffect, useCallback, useState } from "react";
import "./RaceRunners.css";
import useRunnersStore from "../../../../store/useRunnersStore";
import useEventsStore from "../../../../store/useEventStore";
import { useResultsStore } from "../../../../store/useResultStore";
import buttonBackground from "../../../../assets/Race/bet-button-bg.svg";
import BetButton from "./component/BetButton";
import scratchedImage from "../../../../assets/Race/RaceListScratched.png";
import Winner from "./component/Winner";
import thropy1 from "../../../../assets/Race/thropy-1.svg";
import thropy2 from "../../../../assets/Race/thropy-2.svg";
import thropy3 from "../../../../assets/Race/thropy-3.svg";
import thropy4 from "../../../../assets/Race/thropy-4.svg";

import lose from "../../../../assets/Race/lose.svg";

const RaceRunners = ({
  currentRace,
  selectedBetType,
  onBetInput,
  activeButtons,
  isFirstRaceDisabled,
  selectedRaceRange,
}) => {
  const { runners, fetchRunners, raceBettingStatus } = useRunnersStore();
  const { activeEvent } = useEventsStore();
  const { fetchRaceResults, raceResults, racePositions } = useResultsStore();
  const [loadingRunners, setLoadingRunners] = useState(false);

  useEffect(() => {
    if (activeEvent) {
      const race = activeEvent.races.find((r) => r.raceNumber === currentRace);
      if (race) {
        setLoadingRunners(true);
        fetchRunners(race.id, race.betting_status).finally(() => {
          setLoadingRunners(false);
        });
      }
    }
  }, [activeEvent, currentRace, fetchRunners]);

  useEffect(() => {
    if (raceBettingStatus && currentRace) {
      fetchRaceResults(currentRace);
    }
  }, [raceBettingStatus, currentRace, fetchRaceResults]);

  const firstPlaceWinner =
    raceResults.length > 0 && raceResults[0]?.winners.length > 0
      ? raceResults[0].winners[0]
      : "No Winner";

  const handleBetButtonClick = useCallback(
    (runner, label, isActive) => {
      const match = runner.runnerNumber.match(/^\d+/);
      if (!match) return;

      const coupleEntries = runners.filter((r) => {
        const rMatch = r.runnerNumber.match(/^\d+/);
        return rMatch && rMatch[0] === match[0];
      });

      coupleEntries.push(runner);

      coupleEntries.forEach((coupleRunner) => {
        if (["FC", "TRI", "QRT", "PENTA", "SUPER6"].includes(selectedBetType)) {
          onBetInput(
            `${label} Place: ${coupleRunner.runnerNumber}`,
            coupleRunner.id,
            isActive
          );
        } else {
          onBetInput(
            `Race ${currentRace}: ${coupleRunner.runnerNumber}`,
            coupleRunner.id,
            isActive
          );
        }
      });
    },
    [currentRace, onBetInput, runners, selectedBetType]
  );

  const isSecondRaceDisabled = useCallback(() => {
    if (selectedRaceRange) {
      const raceNumbers = selectedRaceRange.split(/[-&]/).map(Number);

      if (isFirstRaceDisabled) {
        if (
          ["DD+1", "PICK5", "PICK6", "WTA"].includes(selectedBetType)
        ) {
          if (raceNumbers.includes(currentRace)) {
            return true;
          }
        } else if (
          ["DD", "XD"].includes(selectedBetType) &&
          raceNumbers.length > 1
        ) {
          const secondRace = raceNumbers[1];
          if (currentRace === secondRace) {
            return true;
          }
        }
      }
    }
    return false;
  }, [currentRace, isFirstRaceDisabled, selectedBetType, selectedRaceRange]);

  const getRunnerPosition = (runnerId) => {
    const positionData = racePositions.find((pos) => pos.runnerId === runnerId);
    return positionData ? positionData.position : null;
  };

  const getPositionImage = (position) => {
    switch (position) {
      case 1:
        return thropy1;
      case 2:
        return thropy2;
      case 3:
        return thropy3;
      case 4:
        return thropy4;
      default:
        return lose;
    }
  };

  const renderBetButtons = (runner) => {
    const labels = {
      FC: ["1st", "2nd"],
      TRI: ["1st", "2nd", "3rd"],
      QRT: ["1st", "2nd", "3rd", "4th"],
      PENTA: ["1st", "2nd", "3rd", "4th", "5th"],
      SUPER6: ["1st", "2nd", "3rd", "4th", "5th", "6th"],
    };

    if (labels[selectedBetType]) {
      return labels[selectedBetType].map((label) => (
        <BetButton
          key={label}
          label={
            raceBettingStatus === "closed"
              ? "Closed"
              : label
          }
          isActive={
            activeButtons[`${label} Place: ${runner.runnerNumber}`]
          }
          onClick={(isActive) =>
            handleBetButtonClick(runner, label, isActive)
          }
          disabled={raceBettingStatus === "closed" || loadingRunners}
          betType={selectedBetType}
        />
      ));
    } else {
      return (
        <BetButton
          label={
            raceBettingStatus === "closed" ||
            isFirstRaceDisabled ||
            isSecondRaceDisabled()
              ? "Closed"
              : "Bet"
          }
          isActive={
            activeButtons[
              `Race ${currentRace}: ${runner.runnerNumber}`
            ]
          }
          onClick={(isActive) =>
            handleBetButtonClick(runner, "Bet", isActive)
          }
          disabled={
            raceBettingStatus === "closed" ||
            isFirstRaceDisabled ||
            isSecondRaceDisabled() ||
            loadingRunners
          }
        />
      );
    }
  };

  return (
    <div className="race-horses">
      <div className="select-all-container">
        {raceBettingStatus === "closed" && firstPlaceWinner !== "No Winner" ? (
          <Winner place="1st" number={firstPlaceWinner} />
        ) : null}
      </div>
      {runners.map((runner) => {
        const position = getRunnerPosition(runner.id);
        const positionImage = getPositionImage(position);

        return (
          <div key={runner.id} className="race-item">
            <div className="runner-number-container">
              <div className="runner-number-detail">
                {runner.runnerNumber}
              </div>
            </div>
            <div className="runner-details">
              <p className="horse-name">{runner.horse.unit.name}</p>
              <p className="jockey-name">Jockey - {runner.jockey.unit.name}</p>
              <p className="trainer-name">
                Trainer - {runner?.trainer?.unit?.name}
              </p>
            </div>
            <div className="bet-buttons">
              <div
                className="button-background"
                style={{ backgroundImage: `url(${buttonBackground})` }}
              >
                {runner.status === "scratch" ? (
                  <img
                    src={scratchedImage}
                    alt="Scratched"
                    className="scratched-image"
                  />
                ) : raceBettingStatus === "closed" ? (
                  <img
                    src={positionImage}
                    alt={`Position ${position}`}
                    className="position-image"
                  />
                ) : (
                  renderBetButtons(runner)
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RaceRunners;
