import React, { useEffect } from "react";
import "./Header.css";
import Messenger1 from "../../assets/Header/chat-room-1.svg";
import Messenger2 from "../../assets/Header/chat-room-2.svg";
import { useAuthStore } from "../../store/useAuthStore";
import { CurrencyFormatter } from "../../formatter/currencyFormatter";

const Header = () => {
  const { fetchUserProfile, user } = useAuthStore((state) => ({
    logout: state.logout,
    fetchUserProfile: state.fetchUserProfile,
    user: state.user,
  }));

  useEffect(() => {
    fetchUserProfile();

    // Set up polling to refresh user profile every 30 seconds
    const intervalId = setInterval(() => {
      fetchUserProfile();
    }, 30000); // 30 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [fetchUserProfile]);

  return (
    <header className="header-container">
      <div className="balance-section">
        <span>Balance:</span>
      </div>
      <div className="amount-section">
        <span>
          <CurrencyFormatter value={user ? user.balance : 0} />
        </span>
      </div>
      <div className="action-buttons-section">
        <a
          href="https://m.me/j/AbaMjBVWizch7PE9/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Messenger1} alt="Chat" className="chat-btn" />
        </a>
        <a
          href="https://m.me/j/AbbePd8Z-lN75Tgn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Messenger2} alt="telegram" className="telegram-btn" />
        </a>
      </div>
    </header>
  );
};

export default Header;
