import React, { useEffect, useState, useCallback } from "react";
import "./RaceNumber.css";
import useEventsStore from "../../../../store/useEventStore";

const RaceNumber = ({ selectedBetType, expectedRaces, onRaceRangeSelect }) => {
  const { pick4set, pick5set, pick6set, wtasset, triset, qrtset, pentaset, super6set } =
    useEventsStore();
  const [raceNumbers, setRaceNumbers] = useState([]);
  const [activeRace, setActiveRace] = useState(null);
  const [previousBetType, setPreviousBetType] = useState(null);
  const [hoveredButton, setHoveredButton] = useState(null);

  const handleRaceRangeSelect = useCallback(
    (race) => {
      setActiveRace(race);
      onRaceRangeSelect(race);
    },
    [onRaceRangeSelect]
  );

  const generateRaceNumbers = useCallback(
    (betType) => {
      switch (betType) {
        case "DD":
          return Array.from(
            { length: expectedRaces - 1 },
            (_, i) => `${i + 1} & ${i + 2}`
          );
        case "FC":
        case "W":
          return Array.from({ length: expectedRaces }, (_, i) =>
            (i + 1).toString()
          );
        case "XD":
          return Array.from(
            { length: expectedRaces - 2 },
            (_, i) => `${i + 1} & ${i + 3}`
          );
        case "DD+1":
          return Array.from(
            { length: expectedRaces - 2 },
            (_, i) => `${i + 1} - ${i + 3}`
          );
        case "PICK4":
          return pick4set || [];
        case "PICK5":
          return pick5set || [];
        case "PICK6":
          return pick6set || [];
        case "WTA":
          return wtasset || [];
        case "TRI":
          return triset || [];
        case "QRT":
          return qrtset || [];
        case "PENTA":
          return pentaset || [];
        case "SUPER6":
          return super6set || [];
        default:
          return [];
      }
    },
    [
      expectedRaces,
      pick4set,
      pick5set,
      pick6set,
      wtasset,
      triset,
      qrtset,
      pentaset,
      super6set,
    ]
  );

  useEffect(() => {
    if (selectedBetType && expectedRaces) {
      const numbers = generateRaceNumbers(selectedBetType);
      setRaceNumbers(numbers);

      if (selectedBetType !== previousBetType) {
        handleRaceRangeSelect(numbers[0]);
        setPreviousBetType(selectedBetType);
      } else if (numbers.length > 0) {
        setActiveRace((prevActiveRace) => {
          if (!prevActiveRace || !numbers.includes(prevActiveRace)) {
            handleRaceRangeSelect(numbers[0]);
            return numbers[0];
          }
          return prevActiveRace;
        });
      }
    }
  }, [
    selectedBetType,
    expectedRaces,
    generateRaceNumbers,
    handleRaceRangeSelect,
    previousBetType,
  ]);

  const handleTouchStart = (index) => {
    setHoveredButton(index);
  };

  const handleTouchMove = () => {
    setHoveredButton(null); // Remove hover effect when scrolling
  };

  const handleTouchEnd = (index) => {
    setHoveredButton(null); // Clear hover effect after touch ends
    handleRaceRangeSelect(raceNumbers[index]); // Ensure the button click works
  };

  return (
    <div
      className="race-number-container"
      onTouchMove={handleTouchMove}
    >
      <div className="race-number-buttons">
        {raceNumbers.map((race, index) => (
          <button
            key={index}
            className={`race-number-button ${
              activeRace === race ? "active" : ""
            } ${hoveredButton === index ? "hovered" : ""}`}
            onClick={() => handleRaceRangeSelect(race)}
            onTouchStart={() => handleTouchStart(index)}
            onTouchEnd={() => handleTouchEnd(index)}
          >
            {race}
          </button>
        ))}
      </div>
    </div>
  );
};

export default RaceNumber;
