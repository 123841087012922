import React from "react";
import "./BackHeader.css";
import Messenger1 from "../../assets/Header/chat-room-1.svg";
import Messenger2 from "../../assets/Header/chat-room-2.svg";
import backIcon from "../../assets/PlayerAccount/back-icon.svg";

const Header = ({ displayText, onBackClick }) => {
  return (
    <header className="header-container">
      <div className="text-section">
        {/* Ensure the onBackClick is properly attached to the img element */}
        <img
          src={backIcon}
          alt="Back"
          className="back-icon"
          onClick={onBackClick}
        />
        <span>{displayText}</span>
      </div>
      <div className="action-buttons-section">
        <a
          href="https://m.me/j/AbaMjBVWizch7PE9/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Messenger1} alt="Chat" className="b-chat-btn" />
        </a>
        <a
          href="https://m.me/j/AbbePd8Z-lN75Tgn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Messenger2} alt="How to Bet" className="b-how-to-bet-btn" />
        </a>
      </div>
    </header>
  );
};

export default Header;
