import React, { useState, useEffect } from "react";
import "./BetModal.css";
import { useBetStore } from "../../../../store/useBetStore";
import useEventsStore from "../../../../store/useEventStore";
import { calculateTotalBet, isConfirmButtonDisabled } from "./betModalUtils";
import { useAuthStore } from "../../../../store/useAuthStore";
import ConfirmationPopup from "../../../../components/Pop-ups/Confirmation";
import ErrorPopup from "../../../../components/Pop-ups/ErrorPopup";

const BetModal = ({
  betInputs,
  setBetInputs,
  activeRace,
  selectedRaceRange,
  selectedBetType,
  setActiveButtons,
}) => {
  const [betAmount, setBetAmount] = useState("");
  const [multiplier, setMultiplier] = useState("");
  const [totalBet, setTotalBet] = useState(0);
  const [betPlaced, setBetPlaced] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { placeBet } = useBetStore();
  const { poolCodes, activeEvent } = useEventsStore();
  const { user } = useAuthStore();

  useEffect(() => {
    console.log("Resetting bet inputs...");
    setBetInputs({});
  }, [selectedRaceRange, setBetInputs]);

  useEffect(() => {
    if (!betPlaced) {
      const calculatedTotalBet = calculateTotalBet(
        betInputs,
        betAmount,
        selectedBetType,
        multiplier
      );
      console.log("Calculated Total Bet in useEffect:", calculatedTotalBet);
      setTotalBet(calculatedTotalBet);
    }
  }, [betInputs, betAmount, selectedBetType, multiplier, betPlaced]);

  const renderInputs = () => {
    const raceNumbers = selectedRaceRange
      ? selectedRaceRange.split(/[-&]/).map(Number)
      : [];
    let inputs = [];
  
    const calculateWidth = (inputValue) => {
      const baseWidth = 25; // Base width in pixels
      const additionalWidth = 10; // Additional width per character
      const numCharacters = inputValue.length;
      return baseWidth + numCharacters * additionalWidth;
    };
  
    const poolTypeInputs = {
      FC: ["1st", "2nd"],
      TRI: ["1st", "2nd", "3rd"],
      QRT: ["1st", "2nd", "3rd", "4th"],
      PENTA: ["1st", "2nd", "3rd", "4th", "5th"],
      SUPER6: ["1st", "2nd", "3rd", "4th", "5th", "6th"],
    };
  
    if (poolTypeInputs[selectedBetType]) {
      // Handle FC, TRI, QRT, PENTA, SUPER 6 inputs
      poolTypeInputs[selectedBetType].forEach((place) => {
        const inputValue =
          betInputs[`${place} Place`]?.map((r) => r.runnerNumber).join(", ") || "";
        inputs.push(
          <div key={`${activeRace}-${place}`} className="bet-input-container">
            <label className="bet-input-label">{place}</label>
            <input
              type="text"
              value={inputValue}
              readOnly
              className="bet-input"
              style={{ width: `${calculateWidth(inputValue)}px` }} // Set dynamic width
            />
          </div>
        );
      });
    } else if (["DD+1", "WTA", "PICK4", "PICK5", "PICK6"].includes(selectedBetType)) {
      // Handle other pool types like DD+1, WTA, PICK5, PICK6
      for (
        let i = raceNumbers[0];
        i <= raceNumbers[raceNumbers.length - 1];
        i++
      ) {
        const inputValue =
          betInputs[`Race ${i}`]?.map((r) => r.runnerNumber).join(", ") || "";
        inputs.push(
          <div key={i} className="bet-input-container">
            <label className="bet-input-label">Race {i}:</label>
            <input
              type="text"
              value={inputValue}
              readOnly
              className="bet-input"
              style={{ width: `${calculateWidth(inputValue)}px` }} // Set dynamic width
            />
          </div>
        );
      }
    } else if (selectedBetType === "W") {
      // Handle Win pool type
      const inputValue =
        betInputs[`Race ${activeRace}`]
          ?.map((r) => r.runnerNumber)
          .join(", ") || "";
      inputs.push(
        <div key={activeRace} className="bet-input-container">
          <label className="bet-input-label">Race {activeRace}:</label>
          <input
            type="text"
            value={inputValue}
            readOnly
            className="bet-input"
            style={{ width: `${calculateWidth(inputValue)}px` }} // Set dynamic width
          />
        </div>
      );
    } else if (["DD", "XD"].includes(selectedBetType)) {
      // Handle DD and XD pool types
      raceNumbers.forEach((raceNumber) => {
        const inputValue =
          betInputs[`Race ${raceNumber}`]
            ?.map((r) => r.runnerNumber)
            .join(", ") || "";
        inputs.push(
          <div key={raceNumber} className="bet-input-container">
            <label className="bet-input-label">Race {raceNumber}:</label>
            <input
              type="text"
              value={inputValue}
              readOnly
              className="bet-input"
              style={{ width: `${calculateWidth(inputValue)}px` }} // Set dynamic width
            />
          </div>
        );
      });
    }
  
    return inputs;
  };
  

  const handleConfirmBet = async () => {
    if (isSubmitting) return;
  
    setIsSubmitting(true);
  
    const poolId = poolCodes.find((pool) => pool.code === selectedBetType)?.id;
    const combinations = [];
    const startRaceNumber = parseInt(selectedRaceRange.split("-")[0].trim());
    const raceNumbers = selectedRaceRange
      .split(/[^0-9]/)
      .map((num) => parseInt(num.trim()))
      .filter(Boolean);
    const endRaceNumber = raceNumbers[raceNumbers.length - 1];
  
    if (selectedBetType === "W") {
      // Handle Win bet logic
      const raceBets = betInputs[`Race ${startRaceNumber}`] || [];
      const positionOneCombinations = raceBets.map((runner) => ({
        position: 1,
        runnerId: runner.runnerId,
      }));
      combinations.push(positionOneCombinations);
    } else if (selectedBetType === "FC") {
      // Handle Forecast bet logic
      const firstRunner = betInputs[`1st Place`] || [];
      const secondRunner = betInputs[`2nd Place`] || [];
  
      const firstPositionCombinations = firstRunner.map((runner) => ({
        position: 1,
        runnerId: runner.runnerId,
      }));
  
      const secondPositionCombinations = secondRunner.map((runner) => ({
        position: 2,
        runnerId: runner.runnerId,
      }));
  
      if (firstPositionCombinations.length > 0) {
        combinations.push(firstPositionCombinations);
      }
  
      if (secondPositionCombinations.length > 0) {
        combinations.push(secondPositionCombinations);
      }
    } else if (["TRI", "QRT", "PENTA", "SUPER6"].includes(selectedBetType)) {
      // Handle TRI, QRT, PENTA, SUPER6 bet logic
      const requiredPositions = ["1st Place", "2nd Place", "3rd Place"];
      if (selectedBetType === "QRT") requiredPositions.push("4th Place");
      if (selectedBetType === "PENTA") requiredPositions.push("4th Place", "5th Place");
      if (selectedBetType === "SUPER6") requiredPositions.push("4th Place", "5th Place", "6th Place");
  
      requiredPositions.forEach((place, index) => {
        const runners = betInputs[place] || [];
        const positionCombinations = runners.map((runner) => ({
          position: index + 1,
          runnerId: runner.runnerId,
        }));
        if (positionCombinations.length > 0) {
          combinations.push(positionCombinations);
        }
      });
    } else if (selectedBetType === "XD") {
      // Handle Exacta bet logic with two races apart
      const firstRaceBets = betInputs[`Race ${startRaceNumber}`] || [];
      const secondRaceBets = betInputs[`Race ${startRaceNumber + 2}`] || []; // Two races ahead
  
      const firstPositionCombinations = firstRaceBets.map((runner) => ({
        position: 1,
        runnerId: runner.runnerId,
      }));
  
      const secondPositionCombinations = secondRaceBets.map((runner) => ({
        position: 2,
        runnerId: runner.runnerId,
      }));
  
      if (firstPositionCombinations.length > 0) {
        combinations.push(firstPositionCombinations);
      }
  
      if (secondPositionCombinations.length > 0) {
        combinations.push(secondPositionCombinations);
      }
    } else {
      // Handle other bet types
      const numPositions =
        selectedBetType === "DD"
          ? 2
          : selectedBetType === "PICK4"
          ? 4
          : selectedBetType === "PICK5"
          ? 5
          : selectedBetType === "PICK6"
          ? 6
          : selectedBetType === "WTA"
          ? 7
          : 3;
  
      for (let i = 0; i < numPositions; i++) {
        const raceNumber = startRaceNumber + i;
        const raceBets = betInputs[`Race ${raceNumber}`] || [];
        const positionCombinations = raceBets.map((runner) => ({
          position: i + 1,
          runnerId: runner.runnerId,
        }));
        combinations.push(positionCombinations);
      }
    }
  
    const lastRace = activeEvent.races.find(
      (r) => r.raceNumber === endRaceNumber
    );
    const raceId = lastRace ? lastRace.id : null;
  
    const initialBetAmount =
      ["DD+1", "DD", "XD", "W", "FC", "TRI"].includes(selectedBetType)
        ? parseFloat(betAmount)
        : 0;
  
    const betData = {
      initialBetAmount,
      pool: {
        poolId,
        poolCode: selectedBetType,
      },
      combinations,
      ...(selectedBetType === "PICK4" ||
      selectedBetType === "PICK5" ||
      selectedBetType === "PICK6" ||
      selectedBetType === "WTA" ||
      selectedBetType === "QRT" ||
      selectedBetType === "PENTA" ||
      selectedBetType === "SUPER6"
        ? { multiplier: Number(multiplier) || 0 }
        : {}),
    };
  
    if (raceId) {
      try {
        const response = await placeBet(raceId, betData);
        if (response.status === 201) {
          setTransactionId(response.data.bet.id);
          setShowConfirmationPopup(true);
          setBetPlaced(true);
          setBetInputs({});
          setActiveButtons({});
        } else {
          setShowErrorPopup(true);
        }
      } catch (error) {
        setErrorMessage(error.message);
        setShowErrorPopup(true);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      console.error("Invalid race ID");
      setIsSubmitting(false);
    }
  };
  

  const handleCloseConfirmationPopup = () => {
    setShowConfirmationPopup(false);
    setTotalBet(0);
    setBetPlaced(false);
  };

  const isButtonDisabled = () => {
    console.log("Checking button state...");
    console.log({
      user: user,
      totalBet: totalBet,
      betInputs: betInputs,
      betAmount: betAmount,
      multiplier: multiplier,
    });
    if (!user || user.balance === 0) {
      console.log("Button Disabled: User not logged in or balance is zero.");
      return true;
    }
    if (totalBet === 0 || totalBet > (user?.balance || 0)) {
      console.log("Button Disabled: Total bet is zero or exceeds balance.");
      return true;
    }
    const disabled = isConfirmButtonDisabled(selectedRaceRange, selectedBetType, betInputs, betAmount, totalBet);
    console.log("Confirm Button Disabled:", disabled);
    return disabled;
  };

  const handleMultiplierChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      console.log("Multiplier Updated:", value);
      setMultiplier(value);
    }
  };

  return (
    <div className="bet-modal">
      <h2 className="bet-modal-title">Place Your Bet</h2>
      <div className="bet-inputs">{renderInputs()}</div>
      {!(
        selectedBetType === "PICK4" ||
        selectedBetType === "PICK5" ||
        selectedBetType === "PICK6" ||
        selectedBetType === "WTA"   ||
        selectedBetType === "QRT"   ||
        selectedBetType === "PENTA" ||
        selectedBetType === "SUPER6"
      ) && (
        <input
          className="bet-amount-input"
          type="number"
          placeholder="Enter Bet Amount"
          value={betAmount}
          onChange={(e) => {
            const value = e.target.value;
            if (
              value === "" ||
              (/^\d+(\.\d{0,2})?$/.test(value) && Number(value) >= 0)
            ) {
              console.log("Bet Amount Updated:", value);
              setBetAmount(value);
            }
          }}
        />
      )}
      {(selectedBetType === "PICK4" ||
        selectedBetType === "PICK5" ||
        selectedBetType === "PICK6" ||
        selectedBetType === "WTA"   ||
        selectedBetType === "QRT"   ||
        selectedBetType === "PENTA" ||
        selectedBetType === "SUPER6") && (
        <input
          className="multiplier-input"
          type="number"
          placeholder="Enter Multiplier"
          value={multiplier}
          onChange={handleMultiplierChange}
        />
      )}
      <button
        className={`confirm-modal-button ${
          isButtonDisabled() ? "disabled" : ""
        }`}
        onClick={handleConfirmBet}
        disabled={isButtonDisabled()}
      >
        Confirm Bet - {totalBet.toFixed(2)}
      </button>
      {totalBet > (user?.balance || 0) && (
        <div className="insufficient-balance-note">
          Insufficient balance to place this bet.
        </div>
      )}

      {showConfirmationPopup && (
        <ConfirmationPopup
          onClose={handleCloseConfirmationPopup}
          transactionId={transactionId}
          amount={totalBet}
          text="You have bet successfully"
        />
      )}
      {showErrorPopup && (
        <ErrorPopup
          onClose={() => setShowErrorPopup(false)}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};

export default BetModal;
