import { create } from "zustand";
import { compare } from "bcrypt-ts";

const apiURL = process.env.REACT_APP_API_URL;

export const useAuthStore = create((set, get) => ({
  user: null,
  isAuthenticated: false,
  token: null,
  refreshToken: null,
  error: null,
  initialLoading: true,
  loginLoading: false,
  registerLoading: false,

  handleApiError: async (response) => {
    if (!response.ok) {
      const errorData = await response.json();
      let errorMessage = "An error occurred";

      switch (response.status) {
        case 400:
          errorMessage = errorData.message || "Bad Request";
          break;
        case 401:
          errorMessage = "Unauthorized access - please log in again.";
          if (errorData.message.message === 'OTP_NOT_VERIFIED') {
            window.location.href = '/otp';
            return;
          }
          get().logout();
          break;
        case 403:
          errorMessage = errorData.message || "Access forbidden";
          break;
        case 404:
          errorMessage = errorData.message || "Resource not found";
          break;
        case 422:
          errorMessage = errorData.message || "Unprocessable entity";
          break;
        case 500:
          errorMessage = "Server error, please try again later.";
          break;
        default:
          errorMessage = errorData.message || errorMessage;
      }
      set({ error: errorMessage });
      return null;
    }
    return await response.json();
  },

  fetchWithAuth: async (url, options = {}) => {
    const { token } = get();
    const authOptions = {
      ...options,
      headers: {
        ...options.headers,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(url, authOptions);
    return get().handleApiError(response);
  },

  loadAuthState: async () => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    set({
      isAuthenticated: true,
      token,
      refreshToken,
      initialLoading: false,
    });
  },


  register: async (
    firstName,
    lastName,
    mobileNumber,
    password,
    referralCode,
    captchaToken
  ) => {
    set({ registerLoading: true });
    try {
      const response = await fetch(`${apiURL}/auth/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName,
          lastName,
          mobileNumber,
          password,
          referralCode,
          captchaToken,
        }),
      });

      const data = await get().handleApiError(response);
      if (data) {
        set({
          user: { mobileNumber, firstName, lastName },
          token: data.accessToken,
          refreshToken: data.refreshToken,
          isAuthenticated: true,
          error: null,
        });
        localStorage.setItem("token", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        window.location.href = '/otp';
      }
    } catch (error) {
      set({ error: error.message });
    } finally {
      set({ registerLoading: false });
    }
  },

  login: async (mobileNumber, password) => {
    if (!mobileNumber || !password) {
      set({ error: "Mobile number and password are required." });
      return;
    }

    set({ loginLoading: true });
    try {
      const response = await fetch(`${apiURL}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mobileNumber, password }),
      });

      const data = await get().handleApiError(response);
      if (data) {
        set({
          user: { mobileNumber },
          token: data.accessToken,
          refreshToken: data.refreshToken,
          isAuthenticated: true,
          error: null,
        });
        localStorage.setItem("token", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
      }
    } catch (error) {
      set({ error: error.message });
    } finally {
      set({ loginLoading: false });
    }
  },

  fetchUserProfile: async () => {
    const data = await get().fetchWithAuth(`${apiURL}/user/profile/me`);
    if (data) {
      if (!data.party.isActive) {
        alert("Your account is inactive. You have been logged out.");
        get().logout();
        return;
      }
      set({
        user: {
          mobileNumber: data.mobileNumber,
          partyId: data.party.account.id,
          firstName: data.firstName,
          lastName: data.lastName,
          balance: data.party.account.balance,
          isActive: data.party.isActive,
        },
        error: null,
      });

      if (!data.party.metadata.isOtpVerified) {
        window.location.href = '/otp';
      }
    }
  },

  logout: () => {
    set({
      user: null,
      token: null,
      refreshToken: null,
      isAuthenticated: false,
      error: null,
    });
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    get().stopTokenCheckInterval();
  },

  stopTokenCheckInterval: () => {
    const { intervalId } = get();
    if (intervalId) {
      clearInterval(intervalId);
      set({ intervalId: null });
    }
  },

  updatePassword: async (currentPassword, newPassword) => {
    const { user, fetchWithAuth } = get();
    if (!user) {
      set({ error: "User not authenticated." });
      return;
    }

    try {
      const profile = await fetchWithAuth(`${apiURL}/user/profile/me`);
      if (!profile) {
        set({ error: "Failed to fetch user profile." });
        return;
      }

      const isCurrentPasswordValid = await compare(
        currentPassword,
        profile.password
      );
      if (!isCurrentPasswordValid) {
        set({ error: "Current password is incorrect." });
        return;
      }

      const response = await fetchWithAuth(`${apiURL}/user/profile/me`, {
        method: "PATCH",
        body: JSON.stringify({ password: newPassword }),
      });

      if (response) {
        alert("Password changed successfully.");
      } else {
        set({ error: "Failed to change password." });
      }
    } catch (error) {
      set({ error: "An error occurred while changing the password." });
    }
  },
}));
