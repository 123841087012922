import React from "react";
import Footer from "../../components/Footer/Footer.jsx";
import { useState, useEffect } from "react";
import kkLogo from "../../assets/TopNavigation/KK-logo.svg";
import GradientButton from "../../components/Button/GradientButton.jsx";
import axios from "axios";

const Otp = () => {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [disableButtons, setDisableButtons] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);


  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    let newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = async (e) => {
    setDisableButtons(true);
    const token = localStorage.getItem("token");

    if (!token) {
      alert("Please login to continue");
      window.location = `/login?c=${(new Date()).valueOf()}`;
      return;
    }

    e.preventDefault();
    if (otp.join("").length !== 6) {
      alert("OTP incomplete, must be 6 digits");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/otp/verify/`,
        { otpCode: otp.join("") },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setDisableButtons(false);
      if (response.data.status) {
        localStorage.clear();
        window.location = `/login?c=${(new Date()).valueOf()}`;
        alert("OTP verified, please login again");
        return;
      }
      alert("Invalid OTP, please try again");
      setOtp(new Array(6).fill(""));
    } catch (error) {
      switch (error.response.data.message) {
        case "OTP_ALREADY_VERIFIED":
          alert("OTP already verified, please login again");
          localStorage.clear();
          window.location = `/login?c=${(new Date()).valueOf()}`;
          break;
        default:
          alert(error.response.data.message);
      }
    }
  };

  const handleResendOtp = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/otp/resend/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      alert("OTP resent successfully");
      setResendTimer(300);
    } catch (error) {
      alert(error.response.data.message);
      return;
    }
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  };

  const titleStyle = {
    fontSize: "20px",
    marginBottom: "20px",
    textAlign: "center",
  };

  const otpContainerStyle = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  };

  const inputStyle = {
    width: "40px",
    height: "40px",
    margin: "0 5px",
    fontSize: "18px",
    textAlign: "center",
    border: "1px solid #ccc",
    borderRadius: "5px",
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendTimer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="how-to-bet-container">
      <div className="logo">
        <img src={kkLogo} alt="Racing Royale Logo" className="logo-image" />
      </div>
      <div style={{ height: "90vh" }}>
        <div style={containerStyle}>
          <h3 style={titleStyle}>Enter OTP sent to your mobile number</h3>
          <form onSubmit={handleSubmit}>
            <div style={otpContainerStyle}>
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  style={inputStyle}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
            <p style={{ marginBottom: 10, textAlign: "center" }}>
              OTP will expire in 10 minutes
            </p>
            <GradientButton
              type="submit"
              text="Submit"
              disabled={disableButtons}
            />
            <div style={{ margin: 10, textAlign: "center" }}>OR</div>
            <button
              type="button"
              style={{ 
                textAlign: "center",
                textDecoration: "underline",
                color: "skyblue",
                fontSize: "20px",
                background: "none",
                margin: "auto",
                display: "block"
              }}
              disabled={resendTimer > 0 || disableButtons}
              onClick={()=>{
                if (resendTimer > 0 || disableButtons) {
                  handleResendOtp()
                }
              }}
            >
              Did not receive otp? Click here to
              {
                resendTimer > 0
                  ? ` resend (${formatTime(resendTimer)})`
                  : " resend"
              }
            </button>
            <GradientButton
              text="Sign Out"
              onClick={() => {
                localStorage.clear();
                window.location = `/login?c=${(new Date()).valueOf()}`;
              }}
              style={{
                marginTop: 100,
                padding: "5px 15px",
                width: "50%",
                background: "",
              }}
            />
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Otp;
